import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Master from './components/Master';
import Home from './components/Home';
import LiveCallTransfers from './components/LiveCallTransfers';
import Chat from './components/Chat'
import Register from './components/Register'
import Agency from './components/Agency'
import Login from './components/Login'
import Users from './components/Users'
import Contact from './components/Contact'
import MessageInput from './components/MessageInput'
import Testing from './components/Testing'
import ChatReport from './components/ChatReport'
import TCPA from './components/TCPA'
import Products from './components/Products'
import Upload from './components/Upload'
import LeadReports from './components/LeadReports'
import AutoQuoteForm from './components/AutoQuoteForm'
import Terms from './components/Terms'
import AutoQuoteFormConfirmation from './components/AutoQuoteFormConfirmation'
import SimpleQuoteForm from './components/SimpleQuoteForm'
import PrivacyPolicy from './components/PrivacyPolicy'

export default () => (
  <div>
    <Router>  
      <Switch>
        <Route exact path="/">  
          <Redirect to="/Home" />  
        </Route>
        <Route exact path='/Home' component={SimpleQuoteForm} />
        <Route exact path='/Terms' component={Terms} />
        <Route exact path='/Confirmation' component={AutoQuoteFormConfirmation} />
        {/* <Route exact path="/">  
          <Redirect to="/Home" />  
        </Route>
        <Master>
          <Route exact path='/Home' component={Home} />
          <Route exact path='/LiveCallTransfers' component={LiveCallTransfers} />
          <Route exact path='/Chat' component={Chat} />
          <Route exact path='/Register' component={Register} />
          <Route exact path='/Agency' component={Agency} />
          <Route exact path='/Login' component={Login} />
          <Route exact path='/Users' component={Users} />
          <Route exact path='/Contact' component={Contact} />
          <Route exact path='/MessageInput' component={MessageInput} />
          <Route exact path='/Testing' component={Testing} />
          <Route exact path='/ChatReport' component={ChatReport} />
          <Route exact path='/TCPA' component={TCPA} />
          <Route exact path='/Products' component={Products} />
          <Route exact path='/Upload' component={Upload} />
          <Route exact path='/LeadReports' component={LeadReports} />
          <Route exact path='/PrivacyPolicy' component={PrivacyPolicy} />
        </Master> */}
      </Switch>  
    </Router>  
  </div>
  );
